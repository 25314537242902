export const findBeginningOfLinePosition = (text, curPos) => {
    // if end of line already, step back 1
    if (text.charAt(curPos) === '\n') {
        return text.lastIndexOf('\n', curPos - 1) + 1;
    }
    return text.lastIndexOf('\n', curPos) + 1;

};

export const insertTextAtPosition = (text, val, pos) => {
    return [text.slice(0, pos), val, text.slice(pos)].join('');
};


const WHITE_SPACE_CHARS = ' \f\n\r\t\v\u00A0\u2028\u2029';

export const isAllWhiteSpaceAfterPos = (text, pos) => {
    let i = pos;
    while (i < text.length - 1) {
        if (WHITE_SPACE_CHARS.indexOf(text[i]) === -1) {
            return false
        }
        i++;
    }
    return true;
};

