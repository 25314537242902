import React from 'react';
import { Col, Container, Navbar, Row, Nav } from 'react-bootstrap';
import TiditTextAreaEditor from './components/TiditTextAreaEditor';

import 'bootstrap/dist/css/bootstrap.min.css'
import './App.css';


class App extends React.Component {
    constructor(props) {
        super(props);
    }


    render() {
        return (
            <>
                <Navbar fixed={"top"}  variant={"dark"} className={"TiditNavbar"}>
                    <Container>
                        <Navbar.Brand href="/">tidit - timed editor</Navbar.Brand>

                        <Nav className="mr-sm-2">
                            <Nav.Link href="mailto:hello@codingthings.com"> @ </Nav.Link>
                            <Nav.Link href="https://www.codingthings.com">www</Nav.Link>
                            <Nav.Link href="https://twitter.com/codingthings">@codingthings</Nav.Link>
                        </Nav>
                    </Container>
                </Navbar>

                <Container className="App">
                    <Row>
                        <Col>
                            <TiditTextAreaEditor/>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }
}

export default App;