import dayjs from 'dayjs'

export const DEFAULT_TIMESTAMP_FORMAT = 'HH:mm:ss';


export const shouldAddTimeStamp = (now, lastEnterKeyCapturedTime, timestampDelay) => {
    return now - lastEnterKeyCapturedTime > timestampDelay * 1000;
};


export const formatTimestamp = (ts, fmt) => {
    return '[' + dayjs(ts).format(fmt) + ']';
};


export const formatTimeStampForEditor = (ts, tsFormat, hasNewLineAfterTimestamp) => {
    return formatTimestamp(ts, tsFormat) + (hasNewLineAfterTimestamp ? '\n' : ' ');

};