import { DEFAULT_TIMESTAMP_FORMAT } from '../utils/DateHelpers';

export const DEFAULT_TS_INSERT_DELAY = 30;

export const TIMESTAMP_FORMATS = [
    DEFAULT_TIMESTAMP_FORMAT,
    'YYYY-MM-DD HH:mm:ss',
];

export const DELAY_TEXTS = {
    '-1': 'off',
    '0': 'every Enter key',
    '10': '10 sec.',
    '20': '20 sec.',
    '30': '30 sec.',
    '60': 'every min.',
    '90': '90 sec.',
    '120': 'every 2 min.',
    '180': 'every 3 min.',
    '300': 'every 5 min.',
};