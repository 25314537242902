import React from 'react';
import {
    Alert,
    Button,
    ButtonGroup,
    ButtonToolbar,
    Col,
    Container,
    Dropdown,
    DropdownButton,
    Form,
    OverlayTrigger,
    Row,
    Tooltip
} from 'react-bootstrap';


import { DEFAULT_TS_INSERT_DELAY, DELAY_TEXTS, TIMESTAMP_FORMATS } from '../constants/TiditConstants';
import { DEFAULT_TIMESTAMP_FORMAT, formatTimeStampForEditor, shouldAddTimeStamp } from '../utils/DateHelpers';
import { findBeginningOfLinePosition, insertTextAtPosition, isAllWhiteSpaceAfterPos } from '../utils/StringHelpers';

import { faAlignJustify, faCalendarAlt, faClock, faMagic, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


import './TiditTextAreaEditor.css';

class TiditTextAreaEditor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hasNewLineAfterTimestamp: false,
            timestampFormat: DEFAULT_TIMESTAMP_FORMAT,
            timestampDelay: DEFAULT_TS_INSERT_DELAY,
            lastEnterKeyCapturedTime: 0,
            text: '',

        };
        this.handleSelectTimestampDelay = this.handleSelectTimestampDelay.bind(this);
        this.handleSelectTimestampFormat = this.handleSelectTimestampFormat.bind(this);
        this.handleEditorOnChange = this.handleEditorOnChange.bind(this);
        this.handleNewLineCheckboxOnChange = this.handleNewLineCheckboxOnChange.bind(this);

        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.addTimestampAtLineHandler = this.addTimestampAtLineHandler.bind(this);
        this.getDelayDropdownTitle = this.getDelayDropdownTitle.bind(this);
        this.renderDelayDropdown = this.renderDelayDropdown.bind(this);

        this.setDomEditorRef = ref => this.domEditor = ref;
    }


    componentDidMount() {
        this.domEditor.focus()
    }


    handleSelectTimestampDelay(evtKey, evt) {
        this.setState({
            timestampDelay: parseInt(evtKey),
        });
    };

    handleSelectTimestampFormat(evtKey, evt) {
        this.setState({
            timestampFormat: evtKey,
        });
    };


    handleNewLineCheckboxOnChange(e) {
        this.setState({
            hasNewLineAfterTimestamp: e.target.checked
        });
    }

    handleEditorOnChange(e) {
        this.setState({text: e.target.value});
    }


    handleKeyDown(e) {
        const {hasNewLineAfterTimestamp, timestampFormat, timestampDelay, lastEnterKeyCapturedTime, text} = this.state;

        const now = new Date();
        let timestampStr = '';
        let updatedText = '';

        const startPosition = e.target.selectionStart;
        const endPosition = e.target.selectionEnd;

        if (startPosition !== endPosition) {
            // do not tinker with selections.
            return;
        }

        if (timestampDelay !== -1) {
            if (e.keyCode === 13) {
                if (isAllWhiteSpaceAfterPos(text, startPosition)
                    && shouldAddTimeStamp(now, lastEnterKeyCapturedTime, timestampDelay)) {
                    // if everything below the cursor is whitespace it will keep adding timestamps.
                    // if a none whitespace char exists, it will not add.
                    timestampStr = (startPosition !== 0 ? '\n' : '')
                        + formatTimeStampForEditor(now, timestampFormat, hasNewLineAfterTimestamp);
                    updatedText = insertTextAtPosition(text, timestampStr, startPosition);
                    this.addTimestampAtPosition(updatedText, startPosition + timestampStr.length, now, false);
                    e.preventDefault();
                    return;
                }
            } else if (text.length === 0) {
                timestampStr = formatTimeStampForEditor(now, timestampFormat, hasNewLineAfterTimestamp);
                this.addTimestampAtPosition(timestampStr, startPosition + timestampStr.length, now, false);
                return;
            }
        }

        if (e.ctrlKey
            && e.shiftKey
            && e.altKey
            && (e.keyCode === 't'.charCodeAt(0) || e.keyCode === 'T'.charCodeAt(0))) {
            const pos = findBeginningOfLinePosition(text, startPosition);
            timestampStr = formatTimeStampForEditor(now, timestampFormat, hasNewLineAfterTimestamp);
            updatedText = insertTextAtPosition(text, timestampStr, pos);
            this.addTimestampAtPosition(updatedText, startPosition + timestampStr.length, now, true);
            e.preventDefault();
        }
    }

    addTimestampAtPosition(text, resetPos, lastEnterKeyCapturedTime, doBlurFocus) {
        this.setState({
            text: text,
            lastEnterKeyCapturedTime: lastEnterKeyCapturedTime
        }, () => {
            this.domEditor.selectionEnd = resetPos;
            // just works. don't fight it.
            if (doBlurFocus === true) {
                this.domEditor.blur();
                this.domEditor.focus();
            }
        });
    }


    addTimestampAtLineHandler(e) {
        const now = new Date();
        const {text, timestampFormat, hasNewLineAfterTimestamp} = this.state;
        const startPosition = this.domEditor.selectionStart;
        //const endPosition = this.domEditor.selectionEnd;

        const pos = findBeginningOfLinePosition(text, startPosition);
        const timestampStr = formatTimeStampForEditor(now, timestampFormat, hasNewLineAfterTimestamp);
        const updatedText = insertTextAtPosition(text, timestampStr, pos);

        this.addTimestampAtPosition(updatedText, startPosition + timestampStr.length, now, true);

        e.preventDefault();
    }

    getDelayDropdownTitle() {
        return (
            <>
                <FontAwesomeIcon icon={faMagic}/>
                <FontAwesomeIcon icon={faClock}/>
                <FontAwesomeIcon icon={faAlignJustify}/>
                <span className={'DropdownDelayText'}>{DELAY_TEXTS[this.state.timestampDelay]}</span>
            </>
        )
    }

    renderDelayDropdown() {
        const items = [];
        let i = 0;
        for (const key in DELAY_TEXTS) {
            i++;
            items.push(
                (<Dropdown.Item key={`'delaydd-${i}'`} eventKey={key}>{DELAY_TEXTS[key]}</Dropdown.Item>))
        }

        return (
            <DropdownButton size={'sm'} as={ButtonGroup} variant={'light'} title={this.getDelayDropdownTitle()}
                            onSelect={this.handleSelectTimestampDelay}>
                {items}
            </DropdownButton>
        );
    }


    getTimestampFormatsDropdownTitle() {
        return (
            <>
                <FontAwesomeIcon icon={faCalendarAlt}/>
                <span className={'DropdownDelayText'}>{this.state.timestampFormat}</span>
            </>
        )
    }

    renderTimestampFormatsDropdown() {
        const items = [];
        let i = 0;
        for (const fmt in TIMESTAMP_FORMATS) {
            i++;
            items.push(
                (<Dropdown.Item key={`tsfmt-${i}`}
                                eventKey={TIMESTAMP_FORMATS[fmt]}>{TIMESTAMP_FORMATS[fmt]}</Dropdown.Item>))
        }

        return (
            <DropdownButton size={'sm'} as={ButtonGroup} variant={'light'}
                            title={this.getTimestampFormatsDropdownTitle()}
                            onSelect={this.handleSelectTimestampFormat}>
                {items}
            </DropdownButton>
        );
    }


    render() {
        const {hasNewLineAfterTimestamp} = this.state;
        return (
            <>
                <Row>
                    <Alert variant={'light'}>
                        <b>tidit</b> adds timestamps to your notes as you type. It can come quite handy while taking
                        notes during interviews.
                        <span className={'DisclaimerNote'}>*Make sure to copy your contents below before navigating away or refreshing this page. Nothing you type on this page is stored!</span>
                    </Alert>
                </Row>

                <Container className={'TiditContainer'}>
                    <Row className={'TiditToolbar'}>
                        <Col xs={12}>
                            <ButtonToolbar>
                                <ButtonGroup className={'mr-3'} size={'sm'}>
                                    <OverlayTrigger
                                        placement="right-start"
                                        delay={{show: 250, hide: 400}}
                                        overlay={
                                            <Tooltip id={'tooltip-'}>
                                                Add timestamp manually at the cursor location. <br/> CTRL + SHIFT + ALT + t
                                            </Tooltip>
                                        }>
                                        <Button variant={'light'} onClick={this.addTimestampAtLineHandler} size={'sm'}>
                                            <FontAwesomeIcon icon={faPlus}/>
                                            <FontAwesomeIcon icon={faClock}/>
                                            <FontAwesomeIcon icon={faAlignJustify}/>
                                        </Button>
                                    </OverlayTrigger>
                                </ButtonGroup>

                                <ButtonGroup className={'mr-3'} size={'sm'}>
                                    <OverlayTrigger
                                        placement="right-start"
                                        delay={{show: 250, hide: 400}}
                                        overlay={
                                            <Tooltip id={'tooltip-'}>
                                                How often would you like the timestamp inserted in your text as you
                                                type?
                                            </Tooltip>
                                        }>
                                        {this.renderDelayDropdown()}
                                    </OverlayTrigger>
                                </ButtonGroup>

                                <ButtonGroup className={'mr-3'} size={'sm'}>
                                    <OverlayTrigger
                                        placement="right-start"
                                        delay={{show: 250, hide: 400}}
                                        overlay={
                                            <Tooltip id={'tooltip-'}>
                                                Timestamp format.<br/>E.g. 14:23:12, 2019-04-28 02:23:55.
                                            </Tooltip>
                                        }>
                                        {this.renderTimestampFormatsDropdown()}
                                    </OverlayTrigger>
                                </ButtonGroup>


                                <Form.Group className={'ml-auto'}>
                                    <Form.Check
                                        className={'ToolbarSmall'}
                                        id={'formNewLineAfterTimestamp'}
                                        type={'checkbox'}
                                        label={'Insert new line after timestamp'}
                                        checked={hasNewLineAfterTimestamp}
                                        onChange={this.handleNewLineCheckboxOnChange}
                                    />
                                </Form.Group>
                            </ButtonToolbar>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <Form.Control
                                className="Editor"
                                as="textarea"
                                placeholder={'start typing...'}
                                value={this.state.text}
                                onChange={this.handleEditorOnChange}
                                ref={this.setDomEditorRef}
                                onKeyDown={this.handleKeyDown}/>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }
}

export default TiditTextAreaEditor;